@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.section {
  order: 5;
  background-color: variable.$primaryColor;
  color: #bda9ce;
  padding: 30px 0 45px;
  .row {
    max-width: 82%;
    width: 100%;
    margin: auto;
    h3 {
      p {
        font-family: variable.$primaryFontBold;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 15px;
        text-align: left;
      }
    }
    p {
      font-family: variable.$primaryFontBold;
      color: #bda9ce;
      font-size: 12px;
      line-height: 15px;
      text-align: justify;
      margin-bottom: 20px;
    }
  }
}
@include mixin.mediaSmallMobileSize() {
  .section {
    .row {
      max-width: 360px;
      h3 {
        p {
          font-size: 20px;
          line-height: 22px;
        }
      }
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
@include mixin.mediaSmallTabletSize() {
  .section {
    padding-inline: 15px;
    .row {
      display: flex;
      gap: 45px;
      max-width: 100%;
      .col {
        flex: 1;
      }
    }
  }
}
